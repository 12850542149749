import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebca2afc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "scanner"
}
const _hoisted_4 = { class: "product" }
const _hoisted_5 = { class: "product-info" }
const _hoisted_6 = { class: "location" }
const _hoisted_7 = { class: "product-count" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_LocationPopover = _resolveComponent("LocationPopover")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Shipment Details")), 1)
                ]),
                _: 1
              }),
              (!_ctx.isShipmentReceived())
                ? (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 0,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHIPMENT_ADMIN),
                        onClick: _ctx.addProduct
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("p", { class: "overline" }, _toDisplayString(_ctx.current.externalOrderName ? _ctx.current.externalOrderName : _ctx.current.externalOrderId), 513), [
                      [_vShow, _ctx.current.externalOrderId || _ctx.current.externalOrderName]
                    ]),
                    (_ctx.current.externalId)
                      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.translate("External ID")) + ": " + _toDisplayString(_ctx.current.externalId), 1))
                      : (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.translate("Shipment ID")) + ": " + _toDisplayString(_ctx.current.shipmentId), 1))
                  ]),
                  _: 1
                }),
                _withDirectives(_createVNode(_component_ion_chip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.current.trackingIdNumber), 1)
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.current.trackingIdNumber]
                ])
              ]),
              _: 1
            }),
            (!_ctx.isShipmentReceived())
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.translate('Scan items'),
                        autofocus: "",
                        placeholder: _ctx.translate('Scan barcodes to receive them'),
                        modelValue: _ctx.queryString,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
                        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.updateProductCount()), ["enter"]))
                      }, null, 8, ["label", "placeholder", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    expand: "block",
                    fill: "outline",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scanCode()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.cameraOutline
                      }, null, 8, ["icon"]),
                      _createTextVNode(_toDisplayString(_ctx.translate("Scan")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.current.items, (item) => {
              return (_openBlock(), _createBlock(_component_ion_card, {
                key: item.id,
                class: _normalizeClass(item.sku === _ctx.lastScannedId ? 'scanned-item' : ''),
                id: item.sku
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_thumbnail, {
                            slot: "start",
                            onClick: ($event: any) => (_ctx.openImage(_ctx.getProduct(item.productId).mainImageUrl, _ctx.getProduct(item.productId).productName))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxpShopifyImg, {
                                src: _ctx.getProduct(item.productId).mainImageUrl
                              }, null, 8, ["src"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      (!_ctx.isShipmentReceived() && item.quantityReceived === 0)
                        ? (_openBlock(), _createBlock(_component_LocationPopover, {
                            key: 0,
                            item: item,
                            type: "shipment",
                            facilityId: _ctx.currentFacility.facilityId
                          }, null, 8, ["item", "facilityId"]))
                        : (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 1,
                            disabled: true,
                            outline: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.locationOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.locationSeqId), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      (!_ctx.isShipmentReceived() && item.quantityReceived === 0)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                label: _ctx.translate('Qty'),
                                disabled: _ctx.isForceScanEnabled,
                                "label-placement": "floating",
                                type: "number",
                                min: "0",
                                modelValue: item.quantityAccepted,
                                "onUpdate:modelValue": ($event: any) => ((item.quantityAccepted) = $event)
                              }, null, 8, ["label", "disabled", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_badge, {
                                  color: "medium",
                                  slot: "end"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.quantityOrdered) + " " + _toDisplayString(_ctx.translate("ordered")), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_badge, {
                                  color: "success",
                                  class: "ion-margin-start",
                                  slot: "end"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.quantityAccepted) + " " + _toDisplayString(_ctx.translate("received")), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                    ])
                  ]),
                  (item.quantityOrdered > 0 && !_ctx.isShipmentReceived() && item.quantityReceived === 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        lines: "none",
                        class: "border-top"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: ($event: any) => (_ctx.receiveAll(item)),
                            disabled: _ctx.isForceScanEnabled,
                            slot: "start",
                            fill: "outline"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Receive All")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick", "disabled"]),
                          _createVNode(_component_ion_progress_bar, {
                            color: _ctx.getRcvdToOrdrdFraction(item) === 1 ? 'success' : _ctx.getRcvdToOrdrdFraction(item) > 1 ? 'danger' : 'primary',
                            value: _ctx.getRcvdToOrdrdFraction(item)
                          }, null, 8, ["color", "value"]),
                          _createElementVNode("p", _hoisted_9, _toDisplayString(item.quantityOrdered) + " " + _toDisplayString(_ctx.translate("shipped")), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["class", "id"]))
            }), 128))
          ]),
          (!_ctx.isShipmentReceived())
            ? (_openBlock(), _createBlock(_component_ion_fab, {
                key: 0,
                vertical: "bottom",
                horizontal: "end",
                slot: "fixed"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, {
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_SHIPMENT_UPDATE) || !_ctx.isEligibleForReceivingShipment(),
                    onClick: _ctx.completeShipment
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDone }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}